<template>
	<div class="mall-product">
		<mallHeader />
		<div class="product-detail container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">智能手机和平板电脑</a></li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">3080显卡</a></li>
			</ul>
			<el-row>
				<el-col :span="4"
					><div class="grid-content bg-purple">
						<tabLeft /></div
				></el-col>
				<el-col :span="20"
					><div class="grid-content bg-purple-light">
						<div class="product-right">
							<div class="product-content">
								<div class="product-content-left">
									<div class="choose" ref="choose">
										<div
											class="content"
											ref="content"
											@mousemove="handleMove"
											@mouseout="handleOut"
										>
											<img src="@/assets/images/1.jpg" />
											<div class="shadow" ref="shadow"></div>
										</div>
									</div>
									<div class="larger" ref="larger">
										<img src="@/assets/images/1.jpg" ref="big" />
									</div>
									<div class="slider-brands">
										<div class="owl2-stage-outer" ref="rampage">
											<div class="fnPrev" @click="fnPrev()">
												<i class="el-icon-arrow-left"></i>
											</div>
											<div class="fnNext" @click="fnNext()">
												<i class="el-icon-arrow-right"></i>
											</div>
											<div class="fixedBox" :ref="`fixedBox`">
												<div
													class="centerScroll"
													:style="
														`width:${120 *
															proList.length}px;transform:translate(${scrollResultWidth}px,0);transition:1s;`
													"
												>
													<div
														class="signleTab"
														v-for="(item, index) in proList"
														:key="index"
													>
														<a href="#"
															><img src="@/assets/images/1.jpg" alt="" />
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="product-content-right">
									<div class="title-product">
										<h1>3080显卡</h1>
									</div>
									<div class="box-review">
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
											<a href="#">0 评论</a><i>|</i
											><a href="#">写一个评论</a>
										</div>
									</div>
									<div class="product-label">
										<div class="product_page_price">
											<span class="price-new">$114.00</span>
											<span class="price-old">$122.00</span>
										</div>
										<div class="stock">
											<span>可用性:</span>
											<span class="status-stock">在 股票</span>
										</div>
									</div>
									<div class="product-box-desc">
										<div class="inner-box-desc">
											<div class="desc-text">
												<span class="text">税交货:</span> $60.00
											</div>
											<div class="desc-text">
												<span class="text">奖励点数的价格:</span> 400
											</div>
											<div class="desc-text">
												<span class="text">品牌:</span><a href="#">苹果</a>
											</div>
											<div class="desc-text">
												<span class="text">产品代码:</span> 产品 15
											</div>
											<div class="desc-text">
												<span class="text">奖励积分:</span> 100
											</div>
										</div>
									</div>
									<div class="product-count">
										<h4>可用选项</h4>
										<label class="control-label">颜色</label>
										<div class="product-options">
											<span
												><img
													src="@/assets/images/demo/colors/blue.jpg"
													alt=""/><i class="el-icon-check checkBox1"></i
											></span>
											<span
												><img
													src="@/assets/images/demo/colors/brown.jpg"
													alt=""/><i class="el-icon-check active checkBox2"></i
											></span>
											<span
												><img
													src="@/assets/images/demo/colors/green.jpg"
													alt=""/><i class="el-icon-check active checkBox3"></i
											></span>
										</div>
										<label class="control-label">复选框</label>
										<el-checkbox-group v-model="checkList">
											<el-checkbox label="复选框 A"></el-checkbox>
											<el-checkbox label="复选框 B"></el-checkbox>
											<el-checkbox label="复选框 C"></el-checkbox>
											<el-checkbox label="复选框 D"></el-checkbox>
										</el-checkbox-group>
										<div class="form-group">
											<div class="input-group">
												<label class="lable">数量</label>
												<input
													class="form-control"
													type="text"
													name="quantity"
													value="1"
												/>
												<span class="product_quantity_down"> - </span>
												<span class="product_quantity_up"> + </span>
											</div>
											<el-tooltip
												class="item"
												effect="dark"
												content="添加购物车"
												placement="top"
											>
												<div class="cart">
													<input
														type="button"
														title=""
														value="添加购物车"
														class="add-to-cart"
													/>
												</div>
											</el-tooltip>
											<el-tooltip
												class="item"
												effect="dark"
												content="Add to Wish List"
												placement="top"
											>
												<div class="love">
													<i class="el-icon-star-on"></i>
												</div>
											</el-tooltip>
											<el-tooltip
												class="item"
												effect="dark"
												content="Compare this Product"
												placement="top"
											>
												<div class="love">
													<i class="el-icon-s-check"></i>
												</div>
											</el-tooltip>
										</div>
									</div>
								</div>
							</div>
							<div class="product-introduce">
								<el-tabs tabPosition="left">
									<el-tab-pane label="描述">
										<p>
											30英寸苹果影院高清显示器提供惊人的2560 x
											1600像素的分辨率。专为创意专业人士设计，这个显示提供了更多的空间，更容易访问所有的工具和调色板，需要编辑，格式和组合您的工作。将该显示器与Mac
											Pro、MacBook Pro或PowerMac
											G5结合使用，您可以实现任何限制。 <br />
											<br />
											Cinema
											HD的特点是采用有源矩阵液晶显示器，可以产生无闪烁的图像，亮度、清晰度和对比度都是典型CRT显示器的两倍。与其他平板不同的是，它采用纯数字界面设计，可以提供无需调整的无失真图像。该显示器拥有超过400万的数字像素，特别适合科学和技术应用，如可视化分子结构或分析地质数据。
											<br />
											<br />
											提供准确、亮丽的色彩表现、影院
											HD可以在广阔的色域内提供1670万种颜色
											让你看到颜色之间的细微差别
											柔和的蜡笔到丰富的宝石色调。宽视角
											确保从边缘到边缘颜色一致。苹果的ColorSync
											技术允许您创建自定义概要文件
											在屏幕上和印刷中保持一致的颜色。的
											结果:您可以放心地在所有您的 color-critical应用程序。
											<br />
											<br />
											在一个新的铝制设计的安置，显示有一个非常
											薄边框，提高视觉精度。每一个显示
											有两个火线400端口和两个USB 2.0端口，
											制作桌面外围设备的附件，如iSight，
											iPod，数码相机，硬盘，打印机，还有
											扫描仪，更容易访问和方便。采取 优点是更薄和更轻的足迹
											LCD，新的显示器支持VESA(视频电子 标准协会)安装接口标准。
											客户可选影院显示VESA Mount
											适配器套件获得了安装他们的显示器的灵活性
											最适合他们工作环境的地点。
											<br />
											<br />
											电影高清的特点是单一的电缆设计与优雅 USB
											2.0，火线400和纯数字 使用行业标准数字视频的连接
											接口(DVI)接口。DVI连接允许 直接纯数字连接。<br />
										</p>
										<h3>特性:</h3>
										<p>
											无与伦比的显示性能
											<br />
											<br />
											30英寸(可视)有源矩阵液晶显示器
											提供惊人的图像质量和生动，丰富 饱和的颜色。
											<br />
											支持2560 × 1600像素分辨率的显示 高清晰度静止和视频图像。
											<br />
											宽幅设计，可同时显示两个全尺寸 文本和图形页。
											<br />
											工业标准DVI连接器直接连接到
											基于Mac和windows的台式电脑和笔记本电脑
											<br />
											水平和垂直的宽度(170度)令人难以置信
											可视角度最大的可视性和颜色 的性能。
											<br />
											闪电般的像素响应全运动数字 视频回放。
											<br />
											支持1670万种饱和颜色，可供全部使用 图形密集的应用程序。
											<br />
											安装操作简单
											<br />
											<br />
											单电缆与优雅的断路器连接DVI， USB和火线接口
											<br />
											内置两端口USB 2.0集线器，方便连接 桌面周边设备。
											<br />
											2个FireWire 400端口，支持iSight和其他桌面 外围设备
											<br />
											时尚,优雅的设计
											<br />
											<br />
											巨大的虚拟工作区，非常小的占用空间。
											<br />
											窄边框设计，最大限度地减少使用的视觉影响 双显示
											<br />
											独特的铰链设计，调整轻松
											<br />
											支持VESA安装解决方案(苹果影院显示器 VESA Mount
											Adapter单独出售)
										</p>
									</el-tab-pane>
									<el-tab-pane label="评论">
										<div class="review">
											<div class="review-top">
												<div class="review-left">
													<strong>超级管理员</strong>
												</div>
												<div class="review-right">
													<span>29/07/2015</span>
												</div>
											</div>
											<div class="review-text">
												<p>本产品最好是开放式的</p>
												<div class="star">
													<ul>
														<li>
															<img src="@/assets/images/icon/星星.svg" alt="" />
														</li>
														<li>
															<img src="@/assets/images/icon/星星.svg" alt="" />
														</li>
														<li>
															<img src="@/assets/images/icon/星星.svg" alt="" />
														</li>
														<li>
															<img src="@/assets/images/icon/星星.svg" alt="" />
														</li>
														<li>
															<img
																src="@/assets/images/icon/灰星星.svg"
																alt=""
															/>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<h2 id="review-title">写一个评论</h2>
										<el-autocomplete
											class="inline-input"
											v-model="state1"
											:fetch-suggestions="querySearch"
											placeholder="你的名字"
										></el-autocomplete>
										<el-input
											type="textarea"
											placeholder="你的评论"
											resize="none"
										>
										</el-input>
										<span style="font-size: 11px;"
											><span class="text-node">请注意:</span>
											HTML没有翻译!</span
										>
										<div class="form-group">
											<b>评级</b> <span>坏</span>&nbsp;
											<input type="radio" name="rating" value="1" /> &nbsp;
											<input type="radio" name="rating" value="2" /> &nbsp;
											<input type="radio" name="rating" value="3" /> &nbsp;
											<input type="radio" name="rating" value="4" /> &nbsp;
											<input type="radio" name="rating" value="5" /> &nbsp;<span
												>好</span
											>
										</div>
										<el-button type="primary" plain>继续</el-button>
									</el-tab-pane>
									<el-tab-pane label="标签">
										<div class="tags">
											<a href="#">Monitor</a>,<a href="#">Apple</a>
										</div>
									</el-tab-pane>
									<el-tab-pane label="自定义标签">
										<div class="tab-title">
											举一个我们曾经做过的小例子
										</div>
										<p>
											Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
											sed diam nonumy eirmod tempor invidunt ut labore et dolore
											magna aliquyam erat, sed diam voluptua. At vero eos et
											accusam et justo duo dolores et ea rebum. Stet clita kasd
											gubergren, no sea takimata sanctus est Lorem ipsum dolor
											sit amet. Lorem ipsum dolor sit amet, consetetur
											sadipscing elitr, sed diam nonumy eirmod tempor invidunt
											ut labore et dolore magna aliquyam erat, sed diam
											voluptua.
											<br />
											<br />
											At vero eos et accusam et justo duo dolores et ea rebum.
											Stet clita kasd gubergren, no sea takimata sanctus est
											Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
											consetetur sadipscing elitr.
											<br />
											<br />
											Nam liberempore
											<br />
											Cumsoluta nobisest
											<br />
											Eligendptio cumque
											<br />
											Nam liberempore
											<br />
											Cumsoluta nobisest
											<br />
											Eligendptio cumque
											<br />
											<br />
											Sed diam nonumy eirmod tempor invidunt ut labore et dolore
											magna aliquyam erat, sed diam voluptua. At vero eos et
											accusam et justo duo dolores et ea rebum. Stet clita kasd
											<br />
											gubergren, no sea takimata sanctus est Lorem ipsum dolor
											sit amet.
										</p>
									</el-tab-pane>
								</el-tabs>
							</div>
							<div class="modtitle">相关产品</div>
							<div class="product-items">
								<div class="fnPrev" @click="fnPrev()">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="fnNext" @click="fnNext()">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="product-item2" v-for="item in 5" :key="item">
									<a href="#"
										><img src="@/assets/images/h1.jpg" alt=""/>
										<img src="@/assets/images/h2.jpg" alt="" class="active"
									/></a>
									<div class="star">
										<ul>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/灰星星.svg" alt="" />
											</li>
										</ul>
									</div>
									<h4>
										<a href="product.html" title="Pastrami bacon" target="_self"
											>时尚切换</a
										>
									</h4>
									<div class="money">
										<span class="now-money">$55.00 </span>
										<span class="old-money">$76.00 </span>
									</div>
									<span class="lable">-9%</span>
									<!-- <span class="eye"
										><img src="@/assets/images/icon/眼睛.svg" alt=""
									/></span> -->
									<span class="refresh el-icon-refresh"></span>
									<span class="love el-icon-star-off"></span>
									<span class="addToCart">添加购物车</span>
								</div>
							</div>
						</div>
					</div></el-col
				>
			</el-row>
		</div>
	</div>
</template>
<script>
import mallHeader from './header.vue'
import tabLeft from './tabLeft.vue'
export default {
	name: 'product',
	components: {
		mallHeader,
		tabLeft,
	},
	data() {
		return {
			scrollResultWidth: 0,
			proList: [
				{ type: '1' },
				{ type: '2' },
				{ type: '3' },
				{ type: '4' },
				{ type: '5' },
			],
			checkList: ['复选框 A'],
			state1: '',
			restaurants: [
				{ value: '啦啦啦' },
				{ value: '啦啦啦' },
				{ value: '啦啦啦' },
			],
		}
	},
	methods: {
		// 获取元素到文档区域的坐标
		getPosition: function(element) {
			var dc = document,
				rec = element.getBoundingClientRect(),
				x = rec.left, // 获取元素相对浏览器视窗window的左、上坐标
				y = rec.top
			// 与html或body元素的滚动距离相加就是元素相对于文档区域document的坐标位置
			x += dc.documentElement.scrollLeft || dc.body.scrollLeft
			y += dc.documentElement.scrollTop || dc.body.scrollTop
			return {
				left: x,
				top: y,
			}
		},
		handleMove(evt) {
			var larger = this.$refs.larger
			var shadow = this.$refs.shadow
			var big = this.$refs.big
			var pos = this.getPosition(this.$refs.choose)
			var shadowRect = shadow.getBoundingClientRect()
			var bigRect = big.getBoundingClientRect()
			var contentRect = this.$refs.content.getBoundingClientRect()
			var maxX = contentRect.width - shadowRect.width
			var maxY = contentRect.height - shadowRect.height
			console.log(maxX, maxY)
			var X = evt.pageX - pos.left - shadowRect.width / 2
			var Y = evt.pageY - pos.top - shadowRect.height / 2

			if (X <= 0) {
				X = 0
			}
			if (X >= maxX) {
				X = maxX
			}
			if (Y <= 0) {
				Y = 0
			}
			if (Y >= maxY) {
				Y = maxY
			}
			// 防止遮罩层粘滞，跟随鼠标一起滑出大图位置
			var bigX = (X * bigRect.width) / contentRect.width / 3
			var bigY = (Y * bigRect.height) / contentRect.height / 3
			//  bigX / bigW = X / contentW,主图和遮罩层之间存在两倍关系，放大图和原图之间也有两倍关系
			shadow.style.left = X + 'px'
			shadow.style.top = Y + 'px'

			// console.log(X, Y, bigX, bigY);

			big.style.left = -bigX + 'px'
			big.style.top = -bigY + 'px'

			larger.style.display = 'block'
			shadow.style.display = 'block'
		},
		handleOut() {
			var larger = this.$refs.larger
			var shadow = this.$refs.shadow
			larger.style.display = 'none'
			shadow.style.top = '-1000px'
		},
		//translate的宽度
		fnPrev() {
			let prev = [...this.proList]
			prev.unshift(prev.pop())
			this.proList = prev
		},
		fnNext() {
			let next = [...this.proList]
			next.push(next.shift())
			this.proList = next
		},
		//input返回建议
		querySearch(queryString, cb) {
			var restaurants = this.restaurants
			var results = queryString
				? restaurants.filter(this.createFilter(queryString))
				: restaurants
			// 调用 callback 返回建议列表的数据
			cb(results)
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (
					restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
					0
				)
			}
		},
	},
}
</script>
<style scoped lang="scss">
.mall-product {
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.product-detail {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.product-right {
			margin-left: 20px;
			.product-content {
				display: flex;
				.product-content-left {
					display: flex;
					flex-direction: column;
					.choose {
						width: 552px;
						height: 552px;
						float: left;
						border: 1px solid #d7d7d7;
						.content {
							width: 550px;
							height: 550px;
							position: relative;
							img {
								width: 550px;
								height: 550px;
							}
						}
					}
					.larger {
						width: 550px;
						height: 550px;
						position: absolute;
						top: 1px;
						left: 221px;
						float: left;
						overflow: hidden;
						display: none;
						img {
							width: 800px;
							height: 800px;
							position: absolute;
							left: 0;
							top: 0;
						}
					}
					.shadow {
						width: 200px;
						height: 200px;
						// background-color: rgba(0, 0, 0, 0);
						position: absolute;
						left: 0;
						top: 0;
						z-index: 10;
						display: none;
					}
					.slider-brands {
						width: 552px;
						// background-color: yellow;
						margin-top: 10px;
						position: relative;
						.owl2-stage-outer {
							overflow: hidden;
							// background-color: #005ea6;
							.fnPrev {
								width: 33px;
								height: 33px;
								border-radius: 33px;
								text-align: center;
								line-height: 33px;
								position: absolute;
								top: 45px;
								left: -18px;
								box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
								background-color: #fff;
								z-index: 5;
							}
							.fnPrev:hover {
								background-color: #005ea6;
								color: #fff;
							}
							.fnNext {
								width: 33px;
								height: 33px;
								border-radius: 33px;
								text-align: center;
								line-height: 33px;
								position: absolute;
								top: 45px;
								right: -18px;
								z-index: 5;
								box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
								background-color: #fff;
							}
							.fnNext:hover {
								background-color: #005ea6;
								color: #fff;
							}
							.fixedBox {
								flex: 1;
								overflow: hidden;
								.centerScroll {
									display: flex;
									.signleTab {
										img {
											width: 120px;
											height: 120px;
											border: 1px solid #d7d7d7;
											margin-right: 24px;
										}
									}
								}
							}
						}
					}
				}
				.product-content-right {
					width: 820px;
					.title-product {
						h1 {
							font-size: 22px;
							margin: 0 0 10px 18px;
							font-weight: 500;
							color: #444;
						}
					}
					.box-review {
						.star {
							display: flex;
							align-items: center;
							margin-bottom: 10px;
							ul {
								display: flex;
								padding-left: 20px;
								margin-bottom: 5px;
								margin-right: 20px;
								li {
									list-style: none;
									img {
										width: 12px;
										height: 12px;
									}
								}
							}
							a {
								margin: 0 5px;
								font-size: 13px;
							}
							i {
								font-size: 13px;
							}
						}
					}
					.product-label {
						display: flex;
						justify-content: space-between;
						margin-left: 21px;
						margin-bottom: 20px;
						.product_page_price {
							.price-new {
								color: #ff5e00;
								font-size: 16px;
								font-weight: 600;
							}
							.price-old {
								line-height: 20px;
								padding: 0 5px;
								display: inline-block;
								text-decoration: line-through;
								color: #aaa;
								font-size: 14px;
								font-weight: normal;
							}
						}
						.stock {
							font-size: 13px;
							.status-stock {
								margin-left: 10px;
								color: #005ea6;
							}
						}
					}
					.product-box-desc {
						margin-left: 21px;
						border-top: 1px solid #d7d7d7;
						border-bottom: 1px solid #d7d7d7;
						margin-bottom: 10px;
						.inner-box-desc {
							margin: 10px 0;
							padding-left: 20px;
							border-left: 3px solid #005ea6;
							.desc-text {
								font-size: 13px;
								font-weight: 400;
								color: #666;
								.text {
									font-weight: 700;
									color: #222;
								}
							}
						}
					}
					.product-count {
						height: 500px;
						margin-left: 21px;
						// background-color: yellow;
						h4 {
							font-size: 16px;
							margin: 15px 0;
							font-weight: 700;
						}
						.control-label {
							font-size: 13px;
							color: #666;
						}
						.control-label::before {
							content: '* ';
							color: #f00;
							font-weight: bold;
						}
						.product-options {
							position: relative;
							margin-top: 10px;
							margin-bottom: 10px;
							img {
								width: 22px;
								height: 22px;
								border: 2px solid #d7d7d7;
								margin-right: 5px;
							}
							.checkBox1 {
								position: absolute;
								top: 3px;
								left: 1px;
								font-weight: 700;
								color: #fff;
								font-size: 18px;
							}
							.checkBox2 {
								position: absolute;
								top: 3px;
								left: 28px;
								font-weight: 700;
								color: #fff;
								font-size: 18px;
							}
							.checkBox3 {
								position: absolute;
								top: 3px;
								left: 55px;
								font-weight: 700;
								color: #fff;
								font-size: 18px;
							}
							.active {
								display: none;
							}
						}
						.el-checkbox-group {
							margin-top: 10px;
							display: flex;
							flex-direction: column;
							margin-bottom: 20px;
							.el-checkbox {
								margin: 5px 0;
							}
							::v-deep {
								.el-checkbox__input.is-checked + .el-checkbox__label {
									color: #666;
								}
								.el-checkbox__label {
									color: #666;
								}
								.el-checkbox__input.is-checked .el-checkbox__inner {
									background-color: #409eff;
									border-color: #409eff;
								}
								.el-checkbox__inner {
									border-color: #222;
								}
							}
						}
						.form-group {
							display: flex;
							height: 45px;
							// background-color: yellow;
							.input-group {
								position: relative;
								width: 100px;
								height: 45px;
								background-color: #eee;
								line-height: 45px;
								.lable {
									float: left;
									font-weight: normal;
									padding-right: 5px;
									font-size: 13px;
									margin-left: 11px;
									color: #666;
								}
								.form-control {
									height: 33px;
									line-height: 31px;
									margin: 0;
									margin-right: 17px;
									padding: 0 10px;
									width: 35px;
									border: 1px solid #bdc2c9;
									border-radius: 0;
									z-index: 0;
								}
								.product_quantity_down {
									background: none repeat scroll 0 0 #444;
									font-size: 13px;
									position: absolute;
									text-align: center;
									right: 14px;
									top: 24px;
									width: 14px;
									height: 15.5px;
									line-height: 15px;
									color: #fff;
								}
								.product_quantity_up {
									background: none repeat scroll 0 0 #444;
									position: absolute;
									right: 14px;
									text-align: center;
									top: 7px;
									color: #fff;
									line-height: 16.5px;
									height: 16.5px;
									width: 14px;
								}
							}
							.cart {
								.add-to-cart {
									background: #005ea6;
									border-radius: 0;
									color: #fff;
									text-transform: uppercase;
									font-size: 13px;
									font-weight: bold;
									padding: 12px 16px;
									border: none;
								}
								.add-to-cart:hover {
									background: #222;
								}
							}
							.love {
								height: 45px;
								width: 45px;
								border: 1px solid #d7d7d7;
								margin-left: 10px;
								text-align: center;
								line-height: 45px;
								font-size: 24px;
								color: #005ea6;
								border-color: #d7d7d7;
							}
							.love:hover {
								color: #005ea6;
								border-color: #222;
							}
						}
					}
				}
			}
			.product-introduce {
				width: 100%;
				// background-color: yellow;
				::v-deep {
					.el-tabs--left .el-tabs__item.is-left {
						text-align: none;
						border-bottom: 1px solid #d7d7d7;
						width: 230px;
						font-weight: 700;
						font-size: 16px;
					}
					.el-tabs__item.is-active {
						color: #005ea6;
					}
					.el-tabs__item:hover {
						color: #005ea6;
					}
					.el-tabs__active-bar {
						position: absolute;
						bottom: 0;
						left: 0;
						height: 2px;
						background-color: #005ea6;
						z-index: 1;
						transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
						list-style: none;
						width: 5px;
					}
				}
				.el-tabs {
					border: 1px solid #d7d7d7;
					p {
						padding: 20px 20px 20px 10px;
						font-size: 13px;
						color: #666;
					}
					h3 {
						font-size: 18px;
						font-weight: 700;
					}
					.review {
						// background-color: yellow;
						margin-top: 20px;
						margin-right: 20px;
						margin-left: 10px;
						border: 1px solid #d7d7d7;
						.review-top {
							height: 35px;
							background-color: #f9f9f9;
							border-bottom: 1px solid #d7d7d7;
							display: flex;
							.review-left {
								width: 550px;
								height: 35px;
								padding-left: 10px;
								border-right: 1px solid #d7d7d7;
								font-size: 14px;
								line-height: 35px;
							}
							.review-right {
								width: 550px;
								height: 35px;
								line-height: 35px;
								text-align: right;
								font-size: 14px;
								color: #666;
								margin-right: 10px;
							}
						}
						.review-text {
							p {
								padding: 10px 0 0 20px;
							}
							.star {
								display: flex;
								align-items: center;
								margin-bottom: 10px;
								ul {
									display: flex;
									padding-left: 20px;
									margin-bottom: 5px;
									margin-right: 20px;
									li {
										list-style: none;
										img {
											width: 12px;
											height: 12px;
										}
									}
								}
							}
						}
					}
					#review-title {
						margin: 20px 0 10px 11px;
						font-size: 20px;
						color: #666;
						font-weight: 500;
					}
					.el-autocomplete {
						width: 100%;
						padding: 0 20px 10px 11px;
						::v-deep {
							.el-input__inner:focus {
								border-color: #409eff;
								box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
									0 0 8px rgb(102 175 233 / 60%);
							}
						}
					}
					::v-deep {
						.el-textarea {
							padding: 0 20px 10px 11px;
						}
						.el-textarea__inner:focus {
							outline: none;
							border-color: #409eff;
							box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
								0 0 8px rgb(102 175 233 / 60%);
						}
						.el-button--primary.is-plain {
							color: #fff;
							background: #005ea6;
							border: none;
							margin: 0 20px 10px 11px;
						}
						.el-button--primary.is-plain:hover,
						.el-button--primary.is-plain:focus {
							background: #222;
							color: #fff;
						}
					}
					.text-node {
						color: #fff;
						display: inline-block;
						background-color: #a94442;
						padding: 3px 5px;
						margin: 0 0 10px 11px;
						border-radius: 3px;
						font-size: 11px;
					}
					.form-group {
						margin: 0 0 20px 11px;
					}
					.tags {
						margin: 20px 0 10px 11px;
						font-size: 13px;
						a {
							color: #666;
							text-decoration: none;
							margin: 0 2px;
						}
						a:hover {
							color: #005ea6;
						}
					}
					.tab-title {
						font-size: 18px;
						font-weight: 700;
						color: #666;
						margin: 20px 20px 0 10px;
					}
				}
			}
			.modtitle {
				font-weight: 700;
				font-size: 16px;
				margin: 40px 0 20px 0;
			}
			.product-items {
				display: flex;
				margin-bottom: 40px;
				position: relative;
				.fnPrev {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					position: absolute;
					top: 120px;
					left: -18px;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					z-index: 5;
					opacity: 0;
				}
				.fnPrev:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.fnNext {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					position: absolute;
					top: 120px;
					right: -18px;
					z-index: 5;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnNext:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.product-item2:hover .active {
					opacity: 0;
					transition: all 0.5s;
				}
				.product-item2:hover .eye {
					opacity: 1;
					animation: change2 0.5s;
				}
				.product-item2:hover .love {
					opacity: 1;
					animation: animLoadedHeader2 0.5s;
				}
				.product-item2:hover .refresh {
					opacity: 1;
					animation: animLoadedHeader1 0.5s;
				}
				.product-item2:hover .addToCart {
					opacity: 1;
					animation: animLoadedHeader3 0.5s;
				}
				.product-item2:hover .star {
					opacity: 0;
				}
				.product-item2:hover h4 {
					opacity: 0;
				}
				.product-item2 {
					position: relative;
					width: 200px;
					text-align: center;
					a {
						img {
							position: relative;
							width: 200px;
							height: 200px;
						}
						.active {
							// opacity: 0;
							position: absolute;
							top: 0;
							right: 0;
						}
					}
					.star {
						ul {
							display: flex;
							padding-left: 70px;
							margin-bottom: 5px;
							li {
								list-style: none;
								img {
									width: 12px;
									height: 12px;
								}
							}
						}
					}
					h4 {
						font-size: 13px;
						color: #333;
					}
					.money {
						margin-bottom: 5px;
						.now-money {
							color: #005ea6;
							font-size: 16px;
							font-weight: 600;
						}
						.old-money {
							text-decoration: line-through;
							color: #aaa;
						}
					}
					.top-right {
						flex: 1;
						// background-color: #005ea6;
						display: flex;
						// justify-content: center;
						margin-left: 88px;
						align-items: center;
						.dot1 {
							width: 30px;
							height: 8px;
							border-radius: 4px;
							background-color: #005ea6;
							margin-right: 5px;
						}
						.dot2 {
							width: 8px;
							height: 8px;
							margin: 0 5px;
							background-color: #222;
							border-radius: 8px;
						}
					}
					.lable {
						width: 38px;
						height: 38px;
						border-radius: 38px;
						background-color: #ffd839;
						text-align: center;
						line-height: 38px;
						position: absolute;
						top: 0;
						right: 0;
					}
					.eye {
						width: 30px;
						height: 30px;
						background-color: #005ea6;
						position: absolute;
						top: 115px;
						right: 115px;
						text-align: center;
						line-height: 30px;
						border-radius: 30px;
						transform-origin: center;
						opacity: 0;
						cursor: pointer;
					}
					.eye:hover {
						background-color: #ff5e00;
					}
					.refresh {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 210px;
						right: 15px;
						text-align: center;
						border-radius: 30px;
						line-height: 25px;
						color: #005ea6;
						opacity: 0;
					}
					.refresh:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.love {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 210px;
						right: 50px;
						text-align: center;
						line-height: 25px;
						border-radius: 30px;
						color: #005ea6;
						opacity: 0;
					}
					.love:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.addToCart {
						height: 30px;
						width: 100px;
						position: absolute;
						top: 210px;
						right: 85px;
						text-align: center;
						background-color: #005ea6;
						color: #fff;
						line-height: 30px;
						border-radius: 15px;
						opacity: 0;
					}
					.addToCart:hover {
						background-color: #ff5e00;
					}
				}
				@keyframes change2 {
					from {
						transform: scale(0.5);
						opacity: 0;
					}
					to {
						transform: scale(1);
						opacity: 1;
					}
				}
				@keyframes animLoadedHeader1 {
					to {
						opacity: 1;
						transform: translate3d(0, 0px, 0);
					}
					from {
						opacity: 0;
						transform: translate3d(0, -40px, 0);
					}
				}
				@keyframes animLoadedHeader2 {
					to {
						opacity: 1;
						transform: translate3d(0, 0px, 0);
					}
					from {
						opacity: 0;
						transform: translate3d(0, -45px, 0);
					}
				}
				@keyframes animLoadedHeader3 {
					to {
						opacity: 1;
						transform: translate3d(0, 0px, 0);
					}
					from {
						opacity: 0;
						transform: translate3d(0, -50px, 0);
					}
				}
			}
			.product-items:hover .fnNext {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-items:hover .fnPrev {
				opacity: 1;
				animation: change2 0.5s;
			}
		}
	}
}
</style>
